<template>
  <div class="iconCell">
    <div :class="[icon,'iconfont']"></div>
    <div class="title">{{ icon }}</div>
  </div>
</template>

<script>
export default {
  name:"IconCell",
  props:{
    icon:{
      type:String,
      default:"lh-icon-update"
    }
  }
}
</script>

<style lang="less" scoped>
.iconCell{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:3px;
  border:solid 1px;
  border-color: rgba(0, 0, 0, 0.3);
  margin: 1px;
  //flex-grow: 1;
  &>.iconfont{
    font-size: 50px;
  }
  &>.title{
    font-size: 13px;
  }
}
</style>
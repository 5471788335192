const iconList=[
  "lh-icon-add",
  "lh-icon-delete",
  "lh-icon-update",
  "lh-icon-search",
  "lh-icon-save",
  "lh-icon-submit",
  "lh-icon-empty",
  "lh-icon-close",
  "lh-icon-file",
  "lh-icon-last",
  "lh-icon-next",
  "lh-icon-anticlockwise",
  "lh-icon-clockwise",
  "lh-icon-scaleup",
  "lh-icon-scaledown",
  "lh-icon-loading",
  "lh-icon-success",
  "lh-icon-warn",
  "lh-icon-error",
  "lh-icon-downArrow",
  "lh-icon-user",
  "lh-icon-password",
  "lh-icon-verify",
  "lh-icon-location",
  "lh-icon-collect"
]
export default iconList;
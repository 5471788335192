<template>
  <div class="icon">
    <model title="Icon" tag="图标">
      <p>提供了一套常用的图标集合。</p>
      <h3>使用方法</h3>
      <p>直接通过设置类名为 lh-icon-iconName 来使用即可。例如：</p>
      <div class="such">
        <span class="lh-icon-add cell"></span>
        <span class="lh-icon-update cell"></span>
        <span class="lh-icon-delete cell"></span>
        <lh-button icon="lh-icon-search">搜索</lh-button>
      </div>
      <lh-code-editor :downloadButton="false" :copyButton="false" readonly v-model="sCode"></lh-code-editor>
      <h3>图标集合</h3>
      <div class="list">
        <icon-cell v-for="iconItem of iconList" :key="iconItem" :icon="iconItem"/>
      </div>
    </model>
  </div>
</template>

<script>
import IconCell from '../../components/IconCell.vue'
import Model from '../../components/Model.vue'
import iconList from '../../static/iconList.js'
export default {
  components: { Model, IconCell },
  name:"IconView",
  data(){
    return{
      sCode:
        "<span class=\"lh-icon-add\"></span>\n"+
        "<span class=\"lh-icon-update\"></span>\n"+
        "<span class=\"lh-icon-delete\"></span>\n"+
        "<lh-button icon=\"lh-icon-search\">搜索</lh-button>",
      iconList:iconList
    }
  }
}
</script>

<style lang="less" scoped>
.icon{
  .such{
    .cell{
      padding: 5px;
      //border: solid;
      font-size: 25px;
    }
  }
  .list{
    display: flex;
    flex-wrap:wrap;
    //justify-content: space-between;
    //max-width: 650px;
  }
  
}
</style>